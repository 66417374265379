import { AxiosResponse } from 'axios';

import { fetcher } from 'src/utils/fetcher';

export interface VacancyBlacklistState {
    employerIsBlacklisted: boolean;
    vacancyIsBlacklisted: boolean;
}

declare global {
    interface FetcherPostApi {
        '/applicant/blacklist/vacancy/add': {
            queryParams: void;
            body: { vacancyId: number };
            response: void;
        };
        '/applicant/blacklist/vacancy/remove': {
            queryParams: void;
            body: { delId: number };
            response: void;
        };
        '/applicant/blacklist/employer/add': {
            queryParams: void;
            body: { employerId: number };
            response: void;
        };
        '/applicant/blacklist/employer/remove': {
            queryParams: void;
            body: { delId: number };
            response: void;
        };
    }
    interface FetcherGetApi {
        '/applicant/blacklist/state': {
            queryParams: {
                vacancyId: number;
                employerId?: number;
            };
            body: void;
            response: VacancyBlacklistState;
        };
    }
}

export const addVacancyRequest = (vacancyId: number): Promise<AxiosResponse<void>> =>
    fetcher.postFormData('/applicant/blacklist/vacancy/add', {
        vacancyId,
    });

export const removeVacancyRequest = (vacancyId: number): Promise<AxiosResponse<void>> =>
    fetcher.postFormData('/applicant/blacklist/vacancy/remove', {
        delId: vacancyId,
    });

export const addEmployerRequest = (employerId: number): Promise<AxiosResponse<void>> =>
    fetcher.postFormData('/applicant/blacklist/employer/add', {
        employerId,
    });

export const removeEmployerRequest = (employerId: number): Promise<AxiosResponse<void>> =>
    fetcher.postFormData('/applicant/blacklist/employer/remove', {
        delId: employerId,
    });

export const fetchVacancyBlacklistState = (
    vacancyId: number,
    employerId?: number,
    signal?: AbortSignal
): Promise<VacancyBlacklistState> =>
    fetcher.get('/applicant/blacklist/state', {
        params: {
            vacancyId,
            employerId,
        },
        signal,
    });

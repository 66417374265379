import { FormEvent } from 'react';
import { AxiosError } from 'axios';

import { fetcher } from 'src/utils/fetcher';

declare global {
    interface FetcherPostApi {
        formAction: {
            body: void;
            queryParams: void;
            response: { success?: string; error?: string };
        };
    }
}

export enum ErrorType {
    LetterRequired = 'letterRequired',
    NotEditable = 'notEditable',
    SpamDetected = 'SPAM_DETECTED',
    ResourcePolicyViolation = 'RESOURCE_POLICY_VIOLATION',
    InappropriateLanguage = 'INAPPROPRIATE_LANGUAGE',
    TooLongLetter = 'TOO_LONG_LETTER',
    Unknown = 'unknown',
}

const handleSubmit = async ({
    event,
    setLoading,
    setErrorCode,
    setSuccessSend,
    onCloseForm,
}: {
    event: FormEvent<HTMLFormElement>;
    setLoading: (v: boolean) => void;
    setErrorCode: (e: ErrorType) => void;
    setSuccessSend: (v: boolean) => void;
    onCloseForm?: () => void;
}): Promise<void> => {
    event.preventDefault();
    const form = event.target as HTMLFormElement;

    if (!form?.checkValidity()) {
        form.reportValidity();

        return;
    }

    const formData = new FormData(form);
    const letterText = form.text as HTMLTextAreaElement;

    if (letterText.value.trim() === '') {
        setErrorCode(ErrorType.LetterRequired);
        return;
    }

    setLoading(true);

    try {
        await fetcher.postFormData(form.action as 'formAction', formData);
    } catch (e) {
        const axiosError = e as AxiosError<{ error?: ErrorType }>;
        const errorMessage = axiosError.response?.data?.error;
        if (errorMessage) {
            setErrorCode(errorMessage);
        }
        return;
    } finally {
        setLoading(false);
    }

    setSuccessSend(true);
    onCloseForm?.();
};

export default handleSubmit;

import { FC, PropsWithChildren } from 'react';

import Markup from 'src/components/Markup';
import { ASYNC_HIGHLIGHT, ASYNC_HIGHLIGHT_SHORT } from 'src/components/Markup/AsyncHighlightProcessor';
import { useLinkifyWhiteList } from 'src/components/Markup/hooks/useLinkifyWhiteList';

const AsyncHighlighter: FC<
    {
        short?: boolean;
        isLinkify?: boolean;
    } & PropsWithChildren
> = ({ short, isLinkify, children }) => {
    const processors = [short ? ASYNC_HIGHLIGHT_SHORT : ASYNC_HIGHLIGHT];

    const linkifyProcessor = useLinkifyWhiteList();

    if (isLinkify && linkifyProcessor) {
        // linkifyProcessor добавляем в начало, чтобы ссылка
        // не разбивалась ASYNC_HIGHLIGHT
        processors.unshift(linkifyProcessor);
    }

    return <Markup processors={processors}>{children}</Markup>;
};

export default AsyncHighlighter;

import { useCallback } from 'react';

import vacancyContactsView from '@hh.ru/analytics-js-events/build/xhh/applicant/contacts/vacancy_contacts_view';
import elementSpy, { Bound, ElementSpyInstance } from 'bloko/common/elementSpy';

interface ContactsShownArguments {
    employerId?: number;
    vacancyId: number;
    chat: boolean;
    callTrackingBanner: boolean;
    mainNumber: boolean;
    addNumber: boolean;
    email: boolean;
}

const useContactsShown = ({
    employerId,
    vacancyId,
    chat,
    callTrackingBanner,
    mainNumber,
    addNumber,
    email,
}: ContactsShownArguments): ((element: HTMLElement) => ElementSpyInstance) => {
    return useCallback(
        (element: HTMLElement) => {
            let wasShown = false;
            const handleElementShown = () => {
                if (wasShown) {
                    return;
                }
                wasShown = true;

                vacancyContactsView({
                    employerId: String(employerId),
                    vacancyId: String(vacancyId),
                    chat,
                    callTrackingBanner,
                    mainNumber,
                    addNumber,
                    email,
                });
            };

            return elementSpy(element, {
                onShow: handleElementShown,
                trackingBounds: [Bound.Top],
            });
        },
        [employerId, vacancyId, chat, callTrackingBanner, mainNumber, addNumber, email]
    );
};

export { useContactsShown };

// https://url.spec.whatwg.org/#url-writing
// https://tools.ietf.org/html/rfc3986#appendix-A
// https://mathiasbynens.be/demo/url-regex
// Символы `(),;:!?` исключены с последней позиции,
// чтобы ссылки не захватывали их в тексте.
const unicode = '\\u00A0-\\uD7FF\\uE000-\\uFDD0\\uFDEF-\\uFFFF';
const host = `-_%\\w${unicode}`;
const last = `${host}~$&'*+=/#@`;
const char = `${last}().,;:!?`;
const prefix = '(((?:https?|ftp)://)|(www.))';
const regExpString = `\\b${prefix}((?:(?:[${host}]+\\.)+[${host}]{2,})(?:[${char}]*[${last}])?)`;

const urlRegExp = new RegExp(regExpString, 'gi');
export { urlRegExp };
